import React from 'react'
import { useState,useEffect } from 'react'
import Loader from '../../COMPONENTS/Loader'
import {MonCompte} from '../MonCompte/Compte'
import {Link} from 'react-router-dom'
import Logo from "../../ASSETS/Images/1.png"

function NumeroClient() {
    const [loading, setLoading] = useState(true);

      useEffect(() => {
        const timer = setTimeout(() => {
          // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
          setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes
    
        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
      }, []);

  return (
    <>
      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loader />
      ) : (
        <>
          <div className="bg-[#ECEEF2] flex flex-col space-y-6 p-4   ">
            <div className="flex items-center justify-between">
              <Link to="/">
                <img src={Logo} alt="logo" className="w-32" />
              </Link>
              <MonCompte />
            </div>

            <div className=" py-10 flex justify-center items-center ">
              {/*Numero client */}
              <div className="bg-white xl:w-[640px]  p-10 rounded-lg shadow flex flex-col items-center justify-center">
                <div className="text-xl">
                  Votre code pour la formation est le suivant :
                </div>
                <div className="text-4xl py-8 text-[#557c93] font-bold">
                  OP09864DFV56
                </div>
                <div className="text-xl text-center ">
                  Veuillez garder ce code car il vous permettra de vous
                  connectez a la reunion
                </div>
              </div>
            </div>

            <div className="flex space-x-20 justify-center">
              <Link
                to="/Service"
                className="w-[200px] bg-white py-3 text-center rounded-full shadow"
              >
                Précédent
              </Link>
              <Link
                to="/Service"
                className="w-[200px] bg-gradient-to-r from-[#557c93] to-[#912f56] py-3 rounded-full text-white text-center shadow"
              >
                Terminer
              </Link>
            </div>

            <div className="flex justify-end relative bottom-6 ">
              <svg
                width="657"
                height="359"
                viewBox="0 0 1157 859"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1156.98 754.876H0V755.452H1156.98V754.876Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M1041.05 791.974H964.414V792.55H1041.05V791.974Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M766.431 798.245H746.323V798.822H766.431V798.245Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M962.101 770.578H917.696V771.154H962.101V770.578Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M221.331 774.451H121.391V775.027H221.331V774.451Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M256.595 774.451H241.948V775.027H256.595V774.451Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M520.989 784.181H304.217V784.757H520.989V784.181Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M548.409 652.043H101.606C98.1056 652.037 94.7509 650.647 92.278 648.179C89.8051 645.71 88.4163 642.365 88.4163 638.878V13.0501C88.4467 9.58241 89.8491 6.26676 92.3186 3.8233C94.7882 1.37985 98.1256 0.0059403 101.606 0H548.409C551.913 0 555.274 1.38706 557.752 3.85605C560.23 6.32503 561.622 9.67369 561.622 13.1654V638.878C561.622 642.369 560.23 645.718 557.752 648.187C555.274 650.656 551.913 652.043 548.409 652.043ZM101.606 0.461135C98.2591 0.467243 95.0514 1.79627 92.6871 4.15649C90.3227 6.51671 88.9947 9.71528 88.9948 13.0501V638.878C88.9947 642.212 90.3227 645.411 92.6871 647.771C95.0514 650.131 98.2591 651.46 101.606 651.466H548.409C551.758 651.46 554.968 650.132 557.336 647.773C559.704 645.413 561.037 642.214 561.043 638.878V13.0501C561.037 9.71316 559.704 6.51465 557.336 4.15508C554.968 1.79552 551.758 0.46723 548.409 0.461135H101.606Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M1048.94 652.043H602.117C598.614 652.037 595.257 650.648 592.781 648.18C590.304 645.713 588.91 642.367 588.904 638.878V13.0501C588.94 9.58031 590.348 6.26468 592.821 3.82186C595.294 1.37904 598.634 0.00587551 602.117 0H1048.94C1052.42 0.0119998 1055.75 1.38859 1058.22 3.83139C1060.68 6.2742 1062.08 9.5864 1062.11 13.0501V638.878C1062.11 642.361 1060.72 645.703 1058.26 648.171C1055.79 650.638 1052.44 652.031 1048.94 652.043ZM602.117 0.461135C598.768 0.46723 595.558 1.79552 593.19 4.15508C590.821 6.51465 589.488 9.71316 589.482 13.0501V638.878C589.488 642.214 590.821 645.413 593.19 647.773C595.558 650.132 598.768 651.46 602.117 651.466H1048.94C1052.29 651.46 1055.5 650.132 1057.87 647.773C1060.24 645.413 1061.57 642.214 1061.58 638.878V13.0501C1061.57 9.71316 1060.24 6.51465 1057.87 4.15508C1055.5 1.79552 1052.29 0.46723 1048.94 0.461135H602.117Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M515.297 296.808H153.069V753.838H515.297V296.808Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M502.477 313.479H165.865V411.216H502.477V313.479Z"
                  fill="#E0E0E0"
                />
                <path
                  d="M184.978 329.295H165.865V411.193H184.978V329.295Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M277.444 329.295H258.331V411.193H277.444V329.295Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M382.405 327.217L363.57 330.452L377.531 411.159L396.367 407.924L382.405 327.217Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M208.673 340.086H189.56V411.193H208.673V340.086Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M252.616 340.086H233.502V411.193H252.616V340.086Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M363.547 340.086H344.434V411.193H363.547V340.086Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M302.554 337.636L283.896 341.767L299.379 411.181L318.037 407.049L302.554 337.636Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M225.218 325.214H215.661V411.193H225.218V325.214Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M338.949 325.214H329.392V411.193H338.949V325.214Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M165.864 524.401H502.477V426.664L165.864 426.664V524.401Z"
                  fill="#E0E0E0"
                />
                <path
                  d="M483.363 524.401H502.477V442.504H483.363V524.401Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M390.898 524.401H410.011V442.504H390.898V524.401Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M271.978 521.177L290.814 524.412L304.776 443.705L285.94 440.47L271.978 521.177Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M459.646 524.401H478.76V453.294H459.646V524.401Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M415.728 524.401H434.841V453.294H415.728V524.401Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M304.772 524.401H323.885V453.294H304.772V524.401Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M350.333 520.253L368.991 524.385L384.473 454.971L365.815 450.84L350.333 520.253Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M443.124 524.401H452.681V438.422H443.124V524.401Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M329.393 524.401H338.949V438.422H329.393V524.401Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M184.978 442.504H165.865V524.401H184.978V442.504Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M208.673 453.294H189.56V524.401H208.673V453.294Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M252.616 453.294H233.502V524.401H252.616V453.294Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M225.218 438.423H215.661V524.401H225.218V438.423Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M502.477 539.872H165.865V637.61H502.477V539.872Z"
                  fill="#E0E0E0"
                />
                <path
                  d="M378.171 555.689H359.058V637.587H378.171V555.689Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M483.151 553.626L464.316 556.861L478.278 637.568L497.113 634.333L483.151 553.626Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M309.4 566.503H290.287V637.609H309.4V566.503Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M353.366 566.503H334.252V637.609H353.366V566.503Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M464.297 566.503H445.184V637.609H464.297V566.503Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M403.322 564.041L384.664 568.172L400.146 637.586L418.804 633.454L403.322 564.041Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M325.945 551.631H316.388V637.61H325.945V551.631Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M439.677 551.631H430.12V637.61H439.677V551.631Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M263.167 553.647L244.331 556.882L258.293 637.589L277.128 634.354L263.167 553.647Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M244.308 566.503H225.195V637.609H244.308V566.503Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M183.321 564.053L164.662 568.185L180.145 637.598L198.803 633.466L183.321 564.053Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M219.688 551.631H210.131V637.61H219.688V551.631Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M706.407 481.031H910.545C915.818 481.031 921.039 482.068 925.909 484.082C930.779 486.096 935.202 489.048 938.925 492.769C942.648 496.489 945.598 500.905 947.605 505.763C949.613 510.622 950.638 515.827 950.623 521.081V679.895H666.422V521.081C666.397 510.488 670.595 500.318 678.093 492.808C685.591 485.298 695.776 481.062 706.407 481.031Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M605.981 598.76H642.495C645.635 598.748 648.746 599.354 651.65 600.543C654.553 601.733 657.193 603.482 659.416 605.691C661.639 607.9 663.403 610.525 664.605 613.415C665.807 616.305 666.424 619.403 666.421 622.531V753.954H582.147V622.531C582.144 619.411 582.758 616.32 583.954 613.436C585.151 610.552 586.906 607.931 589.119 605.724C591.333 603.516 593.961 601.765 596.854 600.57C599.748 599.375 602.849 598.76 605.981 598.76Z"
                  fill="#E0E0E0"
                />
                <path
                  d="M605.981 598.76H606.744C609.876 598.76 612.977 599.375 615.87 600.569C618.764 601.764 621.392 603.516 623.605 605.723C625.819 607.931 627.574 610.552 628.77 613.436C629.967 616.32 630.581 619.41 630.578 622.531V753.954H582.147V622.531C582.144 619.41 582.758 616.32 583.954 613.436C585.151 610.552 586.906 607.931 589.119 605.723C591.333 603.516 593.961 601.764 596.854 600.569C599.748 599.375 602.849 598.76 605.981 598.76Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M920.889 693.499H640.76V753.838H920.889V693.499Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M910.521 638.324H640.366C626.986 638.324 616.139 649.132 616.139 662.464V677.474C616.139 690.807 626.986 701.615 640.366 701.615H910.521C923.902 701.615 934.749 690.807 934.749 677.474V662.464C934.749 649.132 923.902 638.324 910.521 638.324Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M910.059 638.324H876.576C863.196 638.324 852.349 649.132 852.349 662.464V677.474C852.349 690.807 863.196 701.615 876.576 701.615H910.059C923.439 701.615 934.286 690.807 934.286 677.474V662.464C934.286 649.132 923.439 638.324 910.059 638.324Z"
                  fill="#E0E0E0"
                />
                <path
                  d="M913.692 598.76H950.229C953.36 598.76 956.46 599.375 959.352 600.57C962.244 601.765 964.871 603.517 967.082 605.725C969.294 607.933 971.047 610.554 972.241 613.437C973.435 616.321 974.046 619.411 974.04 622.531V753.954H889.881V622.531C889.875 619.411 890.486 616.321 891.68 613.437C892.874 610.554 894.627 607.933 896.839 605.725C899.05 603.517 901.677 601.765 904.569 600.57C907.461 599.375 910.561 598.76 913.692 598.76Z"
                  fill="#E0E0E0"
                />
                <path
                  d="M913.692 598.76H914.479C917.609 598.76 920.709 599.375 923.601 600.57C926.493 601.765 929.12 603.517 931.332 605.725C933.543 607.933 935.296 610.554 936.49 613.437C937.684 616.321 938.295 619.411 938.289 622.531V753.954H889.881V622.531C889.875 619.411 890.486 616.321 891.68 613.437C892.874 610.554 894.627 607.933 896.839 605.725C899.05 603.517 901.677 601.765 904.569 600.57C907.461 599.375 910.561 598.76 913.692 598.76Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M347.687 241.251C377.394 236.446 397.567 208.554 392.745 178.954C387.923 149.353 359.931 129.252 330.224 134.057C300.517 138.861 280.343 166.753 285.165 196.354C289.988 225.954 317.979 246.055 347.687 241.251Z"
                  fill="#E0E0E0"
                />
                <path
                  d="M346.381 233.24C371.648 229.153 388.806 205.431 384.705 180.254C380.604 155.078 356.796 137.981 331.529 142.068C306.262 146.155 289.104 169.877 293.205 195.054C297.307 220.23 321.114 237.326 346.381 233.24Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M336.751 219.361V187.082L315.301 199.187"
                  fill="#F0F0F0"
                />
                <path
                  d="M336.751 221.667C336.137 221.667 335.549 221.424 335.115 220.992C334.681 220.559 334.437 219.973 334.437 219.361V191.025L316.435 201.193C315.9 201.491 315.269 201.567 314.679 201.403C314.089 201.238 313.589 200.848 313.288 200.317C312.986 199.787 312.907 199.16 313.067 198.573C313.227 197.985 313.614 197.485 314.144 197.181L335.617 185.076C335.969 184.879 336.367 184.777 336.77 184.78C337.174 184.784 337.569 184.892 337.918 185.095C338.266 185.298 338.556 185.588 338.757 185.936C338.958 186.285 339.064 186.68 339.065 187.082V219.361C339.065 219.973 338.821 220.559 338.387 220.992C337.953 221.424 337.365 221.667 336.751 221.667Z"
                  fill="#E0E0E0"
                />
                <path
                  d="M991.788 119.157H642.958V427.586H991.788V119.157Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M982.602 127.619H651.682V419.309H982.602V127.619Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M729.338 127.619L651.682 418.664V281.915L692.685 127.619H729.338Z"
                  fill="white"
                />
                <path
                  d="M756.851 127.619L678.501 419.309H666.005L744.356 127.619H756.851Z"
                  fill="white"
                />
                <path
                  d="M982.602 311.68V358.278L966.751 419.309H954.256L982.602 311.68Z"
                  fill="white"
                />
                <path
                  d="M767.103 122.385H761.526V425.834H767.103V122.385Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M875.141 122.385H869.564V425.834H875.141V122.385Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M578.491 859C826.276 859 1027.15 847.315 1027.15 832.9C1027.15 818.485 826.276 806.8 578.491 806.8C330.706 806.8 129.837 818.485 129.837 832.9C129.837 847.315 330.706 859 578.491 859Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M929.982 775.235C929.982 775.235 940.973 751.348 928.408 727.785C915.843 704.221 912.211 698.687 920.217 693.845C928.223 689.003 931.902 712.705 931.902 712.705C935.861 702.002 936.267 690.316 933.059 679.365C927.506 662.327 924.012 634.566 931.602 631.592C939.191 628.618 933.059 666.177 939.677 679.043C943.034 670.037 945.204 660.636 946.133 651.075C947.036 637.379 945.624 615.522 951.617 609.919C957.611 604.316 961.475 608.19 955.574 638.394C952.028 656.45 950.069 674.781 949.72 693.176C949.72 693.176 962.748 653.542 971.517 652.389C980.287 651.236 980.264 664.056 972.767 675.192C965.27 686.329 950.807 706.273 948.447 716.533C948.447 716.533 962.771 686.697 975.636 695.782C988.502 704.866 964.437 722.92 953.7 729.445C942.963 735.97 935.512 776.734 935.512 776.734L929.982 775.235Z"
                  fill="#407BFF"
                />
                <path
                  opacity="0.2"
                  d="M929.982 775.235C929.982 775.235 940.973 751.348 928.408 727.785C915.843 704.221 912.211 698.687 920.217 693.845C928.223 689.003 931.902 712.705 931.902 712.705C935.861 702.002 936.267 690.316 933.059 679.365C927.506 662.327 924.012 634.566 931.602 631.592C939.191 628.618 933.059 666.177 939.677 679.043C943.034 670.037 945.204 660.636 946.133 651.075C947.036 637.379 945.624 615.522 951.617 609.919C957.611 604.316 961.475 608.19 955.574 638.394C952.028 656.45 950.069 674.781 949.72 693.176C949.72 693.176 962.748 653.542 971.517 652.389C980.287 651.236 980.264 664.056 972.767 675.192C965.27 686.329 950.807 706.273 948.447 716.533C948.447 716.533 962.771 686.697 975.636 695.782C988.502 704.866 964.437 722.92 953.7 729.445C942.963 735.97 935.512 776.734 935.512 776.734L929.982 775.235Z"
                  fill="black"
                />
                <path
                  d="M938.659 785.034C938.659 785.034 927.945 760.986 940.788 737.607C953.63 714.227 957.333 708.74 949.396 703.806C941.459 698.871 937.479 722.482 937.479 722.482C933.644 711.737 933.376 700.049 936.715 689.142C942.477 672.172 946.318 644.458 938.751 641.368C931.185 638.279 936.877 675.953 930.12 688.704C926.869 679.653 924.808 670.221 923.988 660.644C923.248 646.948 924.937 625.113 918.99 619.418C913.043 613.723 909.156 617.551 914.709 647.847C918.035 665.938 919.769 684.284 919.893 702.676C919.893 702.676 907.328 662.857 898.604 661.589C889.88 660.321 889.718 673.117 897.077 684.369C904.435 695.621 918.643 715.772 920.957 726.055C920.957 726.055 907.073 696.082 894.022 704.982C880.972 713.881 904.898 732.281 915.542 738.967C926.187 745.654 933.175 786.533 933.175 786.533L938.659 785.034Z"
                  fill="#407BFF"
                />
                <path
                  d="M889.279 767.88H977.696L970.546 830.94H896.429L889.279 767.88Z"
                  fill="#263238"
                />
                <path
                  d="M561.368 820.818V829.095C561.368 829.34 561.32 829.583 561.225 829.81C561.131 830.036 560.993 830.242 560.819 830.415C560.645 830.589 560.438 830.726 560.211 830.82C559.984 830.914 559.74 830.962 559.494 830.962H324.811C322.81 830.963 320.839 830.474 319.071 829.539C317.303 828.603 315.792 827.251 314.671 825.598C313.549 823.946 312.852 822.045 312.639 820.062C312.426 818.079 312.705 816.074 313.45 814.223L387.821 630.692L508.008 631.499L503.912 655.317L477.394 809.451C477.196 810.619 477.256 811.816 477.569 812.959C477.882 814.103 478.441 815.164 479.207 816.07C479.973 816.976 480.928 817.706 482.006 818.207C483.084 818.708 484.258 818.97 485.447 818.973H559.494C559.739 818.97 559.982 819.015 560.209 819.107C560.437 819.198 560.643 819.333 560.818 819.505C560.992 819.677 561.131 819.881 561.225 820.106C561.319 820.332 561.368 820.573 561.368 820.818Z"
                  fill="#263238"
                />
                <path
                  opacity="0.5"
                  d="M561.368 820.818V829.095C561.368 829.34 561.32 829.583 561.225 829.81C561.131 830.036 560.993 830.242 560.819 830.415C560.645 830.589 560.438 830.726 560.211 830.82C559.984 830.914 559.74 830.962 559.494 830.962H324.811C322.81 830.963 320.839 830.474 319.071 829.539C317.303 828.603 315.792 827.251 314.671 825.598C313.549 823.946 312.852 822.045 312.639 820.062C312.426 818.079 312.705 816.074 313.45 814.223L359.567 700.439L387.821 630.692L508.008 631.499L503.912 655.317L492.343 722.342L477.371 809.451C477.173 810.619 477.232 811.816 477.545 812.959C477.859 814.103 478.418 815.164 479.184 816.07C479.95 816.976 480.905 817.706 481.983 818.207C483.06 818.708 484.235 818.97 485.424 818.973H559.471C559.718 818.967 559.963 819.01 560.193 819.1C560.423 819.19 560.633 819.325 560.81 819.497C560.986 819.669 561.127 819.874 561.223 820.101C561.319 820.328 561.368 820.571 561.368 820.818Z"
                  fill="white"
                />
                <path
                  opacity="0.2"
                  d="M485.446 818.95H332.724C331.845 818.948 330.979 818.73 330.203 818.316C329.428 817.901 328.766 817.304 328.277 816.575C327.788 815.846 327.486 815.009 327.398 814.136C327.31 813.264 327.439 812.383 327.773 811.572L392.98 655.294H503.888L477.37 809.428C477.172 810.598 477.232 811.798 477.546 812.942C477.861 814.087 478.422 815.15 479.191 816.056C479.959 816.963 480.917 817.691 481.998 818.191C483.078 818.691 484.255 818.95 485.446 818.95Z"
                  fill="black"
                />
                <path
                  opacity="0.1"
                  d="M508.008 631.476L503.912 655.294L492.366 722.32L359.567 700.439L387.821 630.669L508.008 631.476Z"
                  fill="black"
                />
                <path
                  d="M741.695 289.362H185.302C183.433 289.362 181.583 289.729 179.856 290.442C178.13 291.154 176.561 292.199 175.239 293.516C173.918 294.832 172.87 296.396 172.155 298.116C171.439 299.836 171.071 301.68 171.071 303.542V678.882C171.071 682.643 172.571 686.249 175.239 688.909C177.908 691.568 181.528 693.062 185.302 693.062H741.695C745.471 693.062 749.093 691.569 751.766 688.91C754.438 686.252 755.943 682.645 755.949 678.882V303.542C755.943 299.779 754.438 296.173 751.766 293.514C749.093 290.856 745.471 289.362 741.695 289.362Z"
                  fill="#263238"
                />
                <path
                  opacity="0.6"
                  d="M741.695 289.362H185.302C183.433 289.362 181.583 289.729 179.856 290.442C178.13 291.154 176.561 292.199 175.239 293.516C173.918 294.832 172.87 296.396 172.155 298.116C171.439 299.836 171.071 301.68 171.071 303.542V678.882C171.071 682.643 172.571 686.249 175.239 688.909C177.908 691.568 181.528 693.062 185.302 693.062H741.695C745.471 693.062 749.093 691.569 751.766 688.91C754.438 686.252 755.943 682.645 755.949 678.882V303.542C755.943 299.779 754.438 296.173 751.766 293.514C749.093 290.856 745.471 289.362 741.695 289.362Z"
                  fill="white"
                />
                <path
                  opacity="0.2"
                  d="M197.103 693.038H185.302C181.531 693.038 177.915 691.547 175.247 688.893C172.579 686.239 171.077 682.638 171.071 678.881V303.565C171.068 301.7 171.434 299.854 172.147 298.131C172.861 296.408 173.909 294.842 175.231 293.523C176.553 292.204 178.122 291.157 179.85 290.443C181.579 289.729 183.431 289.362 185.302 289.362H197.103C195.226 289.352 193.366 289.713 191.63 290.423C189.894 291.133 188.316 292.178 186.987 293.498C185.658 294.818 184.604 296.387 183.886 298.115C183.168 299.843 182.8 301.695 182.803 303.565V678.881C182.803 680.748 183.173 682.597 183.893 684.32C184.613 686.044 185.668 687.609 186.997 688.924C188.326 690.24 189.903 691.281 191.637 691.987C193.372 692.693 195.229 693.05 197.103 693.038Z"
                  fill="black"
                />
                <path
                  d="M743.13 301.558H195.715V663.479H743.13V301.558Z"
                  fill="#407BFF"
                />
                <path
                  opacity="0.4"
                  d="M461.867 439.322C488.116 439.322 509.396 418.119 509.396 391.964C509.396 365.809 488.116 344.605 461.867 344.605C435.618 344.605 414.338 365.809 414.338 391.964C414.338 418.119 435.618 439.322 461.867 439.322Z"
                  fill="white"
                />
                <path
                  d="M450.529 384.77V380.436C450.529 378.369 451.353 376.387 452.82 374.925C454.286 373.464 456.276 372.642 458.35 372.642H465.292C466.327 372.63 467.354 372.823 468.314 373.209C469.273 373.595 470.146 374.167 470.882 374.892C471.619 375.617 472.203 376.48 472.602 377.432C473.001 378.383 473.206 379.404 473.206 380.436V384.862H478.273V380.436C478.267 377.031 476.907 373.768 474.492 371.361C472.076 368.954 468.801 367.599 465.385 367.593H458.443C455.026 367.599 451.752 368.954 449.336 371.361C446.92 373.768 445.56 377.031 445.554 380.436V384.862L450.529 384.77Z"
                  fill="white"
                />
                <path
                  d="M439.075 390.603V413.867C439.075 414.206 439.142 414.542 439.272 414.856C439.402 415.169 439.593 415.454 439.834 415.693C440.074 415.933 440.36 416.123 440.675 416.253C440.989 416.383 441.326 416.45 441.666 416.45H482.068C482.409 416.45 482.746 416.383 483.06 416.253C483.374 416.123 483.66 415.933 483.901 415.693C484.141 415.454 484.332 415.169 484.463 414.856C484.593 414.542 484.66 414.206 484.66 413.867V390.603C484.66 389.918 484.387 389.261 483.901 388.777C483.415 388.293 482.756 388.021 482.068 388.021H441.666C441.326 388.021 440.989 388.088 440.675 388.217C440.36 388.347 440.074 388.537 439.834 388.777C439.593 389.017 439.402 389.302 439.272 389.615C439.142 389.928 439.075 390.264 439.075 390.603ZM463.325 403.768V408.38H460.479V403.768C460.063 403.538 459.712 403.205 459.461 402.801C459.21 402.398 459.067 401.937 459.044 401.463C459.044 400.698 459.349 399.965 459.891 399.425C460.434 398.884 461.17 398.581 461.937 398.581C462.704 398.581 463.44 398.884 463.982 399.425C464.524 399.965 464.829 400.698 464.829 401.463C464.8 401.944 464.646 402.409 464.382 402.813C464.118 403.217 463.754 403.546 463.325 403.768Z"
                  fill="white"
                />
                <path
                  d="M345.915 478.541H301.024C298.353 478.541 296.188 480.699 296.188 483.36V528.09C296.188 530.751 298.353 532.909 301.024 532.909H345.915C348.586 532.909 350.751 530.751 350.751 528.09V483.36C350.751 480.699 348.586 478.541 345.915 478.541Z"
                  fill="white"
                />
                <path
                  d="M415.125 478.472H370.234C367.564 478.472 365.398 480.63 365.398 483.291V528.021C365.398 530.682 367.564 532.84 370.234 532.84H415.125C417.796 532.84 419.962 530.682 419.962 528.021V483.291C419.962 480.63 417.796 478.472 415.125 478.472Z"
                  fill="white"
                />
                <path
                  d="M484.313 478.403H439.422C436.751 478.403 434.586 480.56 434.586 483.222V527.952C434.586 530.613 436.751 532.771 439.422 532.771H484.313C486.984 532.771 489.149 530.613 489.149 527.952V483.222C489.149 480.56 486.984 478.403 484.313 478.403Z"
                  fill="white"
                />
                <path
                  d="M553.5 478.311H508.609C505.938 478.311 503.773 480.468 503.773 483.129V527.859C503.773 530.521 505.938 532.678 508.609 532.678H553.5C556.171 532.678 558.336 530.521 558.336 527.859V483.129C558.336 480.468 556.171 478.311 553.5 478.311Z"
                  fill="white"
                />
                <path
                  d="M622.688 478.242H577.797C575.126 478.242 572.961 480.399 572.961 483.061V527.791C572.961 530.452 575.126 532.609 577.797 532.609H622.688C625.359 532.609 627.524 530.452 627.524 527.791V483.061C627.524 480.399 625.359 478.242 622.688 478.242Z"
                  fill="white"
                />
                <path
                  d="M588.234 506.486C588.234 496.917 593.371 491.476 600.266 491.476C607.162 491.476 612.276 496.917 612.276 506.486C612.276 516.055 607.139 521.496 600.266 521.496C593.394 521.496 588.234 516.055 588.234 506.486ZM608.064 506.486C608.064 498.97 604.848 495.211 600.266 495.211C595.684 495.211 592.445 498.97 592.445 506.486C592.445 514.002 595.638 517.761 600.266 517.761C604.894 517.761 608.064 513.979 608.064 506.486Z"
                  fill="#263238"
                />
                <path
                  d="M334.437 506.117C334.437 516.123 328.745 521.496 320.554 521.496C317.997 521.587 315.463 520.989 313.218 519.766L314.699 516.469C316.418 517.46 318.385 517.94 320.368 517.853C326.338 517.853 330.133 514.118 330.133 506.762V505.725C329.146 507.013 327.853 508.037 326.371 508.705C324.889 509.373 323.264 509.664 321.641 509.552C315.787 509.552 311.575 506.071 311.575 500.675C311.575 495.28 316.203 491.453 321.965 491.453C329.971 491.476 334.437 496.756 334.437 506.117ZM329.254 500.537C329.254 497.517 326.94 494.865 322.312 494.865C321.502 494.768 320.68 494.842 319.901 495.083C319.122 495.324 318.403 495.727 317.791 496.265C317.179 496.803 316.688 497.464 316.351 498.204C316.013 498.944 315.837 499.747 315.833 500.56C315.833 504.019 318.402 506.232 322.544 506.232C326.686 506.232 329.254 503.765 329.254 500.537Z"
                  fill="#263238"
                />
                <path
                  d="M380.647 506.486C380.647 496.917 385.784 491.476 392.68 491.476C399.575 491.476 404.689 496.917 404.689 506.486C404.689 516.055 399.552 521.496 392.68 521.496C385.807 521.496 380.647 516.055 380.647 506.486ZM400.478 506.486C400.478 498.97 397.284 495.211 392.68 495.211C388.075 495.211 384.859 498.97 384.859 506.486C384.859 514.002 388.052 517.761 392.68 517.761C397.308 517.761 400.478 514.002 400.478 506.486Z"
                  fill="#263238"
                />
                <path
                  d="M472.512 517.507V521.081H451.339V518.314L463.326 506.786C466.449 503.765 467.028 501.921 467.028 500.076C467.028 497.056 464.899 495.211 460.757 495.211C459.343 495.133 457.929 495.379 456.626 495.931C455.322 496.482 454.162 497.325 453.237 498.393L450.367 495.926C451.721 494.434 453.391 493.26 455.256 492.491C457.121 491.721 459.134 491.374 461.15 491.476C467.282 491.476 471.239 494.612 471.239 499.638C471.239 502.497 470.406 505.057 466.403 508.861L457.402 517.484L472.512 517.507Z"
                  fill="#263238"
                />
                <path
                  d="M534.526 491.799V521.081H530.361V495.465H523.697V491.799H534.526Z"
                  fill="#263238"
                />
                <g opacity="0.6">
                  <path
                    d="M410.105 465.261V466.298H402.075V454.977H409.85V456.015H403.278V460.05H409.133V461.134H403.278V465.33L410.105 465.261Z"
                    fill="white"
                  />
                  <path
                    d="M420.517 461.364V466.298H419.36V461.479C419.429 461.121 419.412 460.752 419.309 460.402C419.207 460.052 419.023 459.731 418.773 459.465C418.522 459.2 418.212 458.997 417.867 458.874C417.523 458.752 417.154 458.712 416.791 458.758C416.384 458.729 415.975 458.79 415.593 458.935C415.212 459.08 414.866 459.307 414.582 459.599C414.298 459.891 414.081 460.242 413.947 460.626C413.813 461.011 413.765 461.42 413.806 461.825V466.298H412.673V457.79H413.76V459.358C414.108 458.827 414.591 458.397 415.16 458.113C415.73 457.83 416.365 457.702 417 457.744C417.482 457.703 417.967 457.769 418.421 457.939C418.874 458.108 419.284 458.375 419.621 458.722C419.958 459.068 420.213 459.485 420.368 459.942C420.523 460.399 420.574 460.885 420.517 461.364Z"
                    fill="white"
                  />
                  <path
                    d="M428.084 465.745C427.575 466.156 426.934 466.369 426.279 466.344C425.946 466.372 425.611 466.327 425.296 466.213C424.982 466.099 424.697 465.919 424.459 465.684C424.222 465.45 424.038 465.167 423.921 464.855C423.803 464.543 423.755 464.209 423.78 463.877V458.828H422.253V457.859H423.78V456.015H424.937V457.859H427.529V458.828H424.937V463.9C424.937 464.915 425.446 465.445 426.395 465.445C426.855 465.459 427.305 465.312 427.667 465.03L428.084 465.745Z"
                    fill="white"
                  />
                  <path
                    d="M437.339 462.401H430.212C430.265 463.236 430.649 464.015 431.278 464.568C431.907 465.121 432.73 465.403 433.567 465.353C434.057 465.368 434.545 465.278 434.997 465.086C435.449 464.895 435.853 464.609 436.182 464.246L436.83 464.984C436.199 465.574 435.414 465.976 434.565 466.145C433.715 466.313 432.836 466.24 432.026 465.935C431.216 465.63 430.508 465.105 429.983 464.419C429.458 463.733 429.136 462.914 429.055 462.056C429.023 461.495 429.108 460.933 429.303 460.406C429.499 459.88 429.802 459.399 430.193 458.994C430.584 458.589 431.055 458.269 431.576 458.054C432.097 457.839 432.656 457.734 433.22 457.744C433.782 457.733 434.34 457.839 434.858 458.054C435.377 458.27 435.845 458.591 436.232 458.996C436.62 459.402 436.918 459.883 437.109 460.41C437.299 460.937 437.378 461.497 437.339 462.056C437.339 462.056 437.339 462.263 437.339 462.401ZM430.212 461.548H436.251C436.185 460.796 435.838 460.095 435.279 459.585C434.72 459.075 433.99 458.792 433.232 458.792C432.474 458.792 431.743 459.075 431.184 459.585C430.625 460.095 430.278 460.796 430.212 461.548Z"
                    fill="white"
                  />
                  <path
                    d="M443.98 457.744V458.828H443.702C443.296 458.806 442.889 458.875 442.512 459.029C442.135 459.183 441.798 459.419 441.523 459.719C441.249 460.019 441.044 460.376 440.925 460.764C440.806 461.152 440.774 461.562 440.833 461.963V466.298H439.653V457.79H440.764V459.45C441.061 458.873 441.529 458.4 442.104 458.095C442.679 457.79 443.333 457.668 443.98 457.744Z"
                    fill="white"
                  />
                  <path
                    d="M457.586 457.79L453.329 467.312C452.611 468.972 451.709 469.503 450.483 469.503C450.104 469.516 449.727 469.454 449.373 469.319C449.019 469.185 448.696 468.98 448.423 468.719L448.955 467.866C449.153 468.077 449.393 468.244 449.661 468.355C449.928 468.467 450.216 468.52 450.506 468.511C451.269 468.511 451.778 468.165 452.264 467.105L452.635 466.275L448.909 457.79H450.112L453.306 465.007L456.522 457.79H457.586Z"
                    fill="white"
                  />
                  <path
                    d="M458.165 462.055C458.161 461.197 458.412 460.357 458.887 459.641C459.362 458.925 460.04 458.366 460.835 458.034C461.629 457.703 462.505 457.613 463.35 457.778C464.195 457.943 464.973 458.354 465.583 458.959C466.194 459.564 466.611 460.337 466.78 461.178C466.95 462.019 466.865 462.892 466.537 463.686C466.208 464.479 465.651 465.157 464.935 465.635C464.219 466.112 463.377 466.367 462.516 466.367C461.94 466.389 461.366 466.293 460.83 466.085C460.293 465.877 459.806 465.56 459.397 465.156C458.989 464.751 458.669 464.267 458.457 463.733C458.245 463.2 458.146 462.629 458.165 462.055ZM465.663 462.055C465.635 461.436 465.426 460.839 465.06 460.337C464.695 459.836 464.189 459.453 463.607 459.235C463.024 459.018 462.391 458.976 461.785 459.115C461.179 459.254 460.627 459.568 460.198 460.017C459.77 460.466 459.483 461.031 459.374 461.641C459.265 462.251 459.339 462.879 459.586 463.448C459.833 464.017 460.242 464.501 460.763 464.839C461.284 465.178 461.894 465.356 462.516 465.352C462.939 465.35 463.357 465.262 463.745 465.092C464.133 464.923 464.482 464.676 464.77 464.367C465.058 464.058 465.28 463.694 465.422 463.296C465.563 462.898 465.622 462.476 465.593 462.055H465.663Z"
                    fill="white"
                  />
                  <path
                    d="M476.793 457.79V466.298H475.705V464.73C475.388 465.254 474.935 465.683 474.393 465.972C473.851 466.26 473.241 466.396 472.627 466.367C472.138 466.415 471.645 466.354 471.183 466.186C470.721 466.019 470.302 465.751 469.958 465.402C469.614 465.052 469.353 464.63 469.194 464.167C469.036 463.703 468.983 463.21 469.041 462.724V457.79H470.175V462.609C470.106 462.967 470.123 463.336 470.225 463.686C470.327 464.036 470.511 464.357 470.762 464.622C471.012 464.888 471.323 465.09 471.667 465.213C472.012 465.336 472.38 465.376 472.743 465.329C473.145 465.353 473.547 465.287 473.922 465.139C474.296 464.99 474.632 464.762 474.908 464.469C475.184 464.177 475.392 463.828 475.518 463.447C475.643 463.065 475.684 462.661 475.636 462.263V457.79H476.793Z"
                    fill="white"
                  />
                  <path
                    d="M484.313 457.744V458.828H484.035C483.628 458.807 483.222 458.875 482.845 459.03C482.468 459.184 482.13 459.419 481.856 459.719C481.581 460.019 481.377 460.376 481.258 460.764C481.139 461.152 481.107 461.562 481.166 461.964V466.298H480.009V457.79H481.12V459.45C481.416 458.877 481.88 458.407 482.45 458.102C483.02 457.798 483.67 457.673 484.313 457.744Z"
                    fill="white"
                  />
                  <path
                    d="M489.982 460.649C489.941 459.465 490.255 458.295 490.886 457.29C491.516 456.285 492.433 455.49 493.52 455.008C494.606 454.526 495.813 454.378 496.984 454.583C498.156 454.789 499.239 455.338 500.095 456.161C500.951 456.985 501.54 458.044 501.788 459.203C502.036 460.362 501.93 461.569 501.485 462.668C501.04 463.767 500.276 464.708 499.29 465.372C498.304 466.035 497.142 466.39 495.952 466.39C495.178 466.422 494.404 466.296 493.68 466.021C492.955 465.746 492.294 465.328 491.736 464.791C491.178 464.255 490.735 463.611 490.433 462.899C490.132 462.187 489.979 461.422 489.982 460.649ZM500.696 460.649C500.723 459.699 500.464 458.762 499.951 457.96C499.438 457.158 498.695 456.528 497.818 456.151C496.942 455.775 495.972 455.669 495.035 455.848C494.097 456.027 493.236 456.482 492.561 457.154C491.886 457.827 491.429 458.686 491.25 459.62C491.07 460.554 491.176 461.52 491.554 462.393C491.932 463.267 492.564 464.007 493.369 464.518C494.174 465.029 495.114 465.288 496.068 465.261C497.296 465.261 498.473 464.775 499.341 463.91C500.208 463.045 500.696 461.872 500.696 460.649Z"
                    fill="white"
                  />
                  <path
                    d="M506.619 456.015H502.639V454.977H511.895V456.015H507.892V466.298H506.689L506.619 456.015Z"
                    fill="white"
                  />
                  <path
                    d="M522.609 458.828C522.609 461.133 520.851 462.678 517.981 462.678H514.95V466.252H513.7V454.931H517.935C520.851 454.977 522.609 456.522 522.609 458.828ZM521.406 458.828C521.406 457.006 520.203 455.969 517.935 455.969H514.927V461.618H517.935C520.203 461.664 521.406 460.649 521.406 458.828Z"
                    fill="white"
                  />
                </g>
                <g opacity="0.6">
                  <path
                    d="M416.861 562.33L417.324 561.43C418.362 562.329 419.697 562.813 421.073 562.791C423.178 562.791 424.104 561.914 424.104 560.785C424.104 557.695 417.162 559.586 417.162 555.413C417.162 553.752 418.458 552.323 421.327 552.323C422.571 552.306 423.793 552.651 424.844 553.314L424.451 554.26C423.517 553.666 422.435 553.347 421.327 553.337C419.268 553.337 418.342 554.26 418.342 555.389C418.342 558.479 425.284 556.611 425.284 560.716C425.284 562.399 423.965 563.805 421.073 563.805C419.532 563.863 418.027 563.335 416.861 562.33Z"
                    fill="white"
                  />
                  <path
                    d="M435.026 559.816H427.899C427.952 560.65 428.335 561.429 428.964 561.982C429.593 562.535 430.416 562.818 431.254 562.767C431.744 562.783 432.232 562.692 432.684 562.501C433.135 562.31 433.54 562.023 433.869 561.661L434.517 562.398C434.109 562.862 433.602 563.23 433.034 563.473C432.465 563.716 431.849 563.83 431.231 563.805C430.644 563.84 430.056 563.753 429.504 563.55C428.953 563.347 428.45 563.031 428.027 562.623C427.605 562.215 427.273 561.724 427.051 561.182C426.83 560.639 426.724 560.056 426.742 559.47C426.713 558.91 426.8 558.35 426.997 557.825C427.194 557.3 427.497 556.82 427.888 556.416C428.278 556.012 428.747 555.692 429.267 555.476C429.786 555.259 430.344 555.151 430.907 555.159C431.47 555.144 432.031 555.248 432.552 555.462C433.074 555.676 433.544 555.996 433.934 556.402C434.324 556.808 434.625 557.29 434.817 557.819C435.009 558.347 435.088 558.91 435.049 559.47C435.049 559.47 435.026 559.701 435.026 559.816ZM427.899 558.963H433.938C433.872 558.21 433.525 557.51 432.966 556.999C432.407 556.489 431.676 556.206 430.918 556.206C430.16 556.206 429.43 556.489 428.871 556.999C428.312 557.51 427.965 558.21 427.899 558.963Z"
                    fill="white"
                  />
                  <path
                    d="M445.276 558.779V563.713H444.142V558.894C444.206 558.538 444.186 558.171 444.082 557.824C443.978 557.477 443.793 557.16 443.543 556.897C443.293 556.634 442.984 556.434 442.642 556.312C442.3 556.19 441.934 556.151 441.573 556.196C441.166 556.167 440.757 556.228 440.375 556.373C439.993 556.518 439.648 556.745 439.364 557.037C439.079 557.329 438.862 557.68 438.728 558.064C438.594 558.449 438.547 558.858 438.588 559.263V563.713H437.339V555.228H438.449V556.796C438.794 556.26 439.276 555.826 439.846 555.539C440.415 555.251 441.052 555.12 441.689 555.159C442.177 555.107 442.67 555.165 443.132 555.329C443.594 555.494 444.013 555.759 444.358 556.107C444.703 556.455 444.964 556.876 445.123 557.339C445.282 557.801 445.334 558.293 445.276 558.779Z"
                    fill="white"
                  />
                  <path
                    d="M456.222 551.723V563.713H455.111V562.029C454.773 562.591 454.289 563.052 453.711 563.364C453.133 563.676 452.482 563.828 451.825 563.805C450.729 563.725 449.704 563.235 448.956 562.433C448.208 561.631 447.792 560.577 447.792 559.482C447.792 558.387 448.208 557.332 448.956 556.53C449.704 555.728 450.729 555.238 451.825 555.159C452.465 555.136 453.1 555.279 453.668 555.574C454.236 555.869 454.717 556.306 455.065 556.842V551.723H456.222ZM455.088 559.47C455.061 558.852 454.852 558.255 454.488 557.755C454.123 557.254 453.619 556.87 453.038 556.652C452.457 556.434 451.824 556.391 451.219 556.528C450.613 556.665 450.061 556.977 449.632 557.423C449.202 557.87 448.914 558.433 448.802 559.041C448.691 559.65 448.761 560.278 449.005 560.847C449.248 561.416 449.654 561.902 450.172 562.243C450.69 562.584 451.297 562.767 451.918 562.767C452.348 562.774 452.775 562.693 453.172 562.528C453.569 562.363 453.928 562.118 454.225 561.808C454.523 561.499 454.753 561.131 454.902 560.729C455.05 560.327 455.114 559.898 455.088 559.47Z"
                    fill="white"
                  />
                  <path
                    d="M470.105 558.455V563.712H468.994V562.398C468.673 562.87 468.231 563.249 467.715 563.496C467.199 563.743 466.626 563.85 466.055 563.804C464.135 563.804 462.955 562.79 462.955 561.314C462.955 559.839 463.788 559.009 466.241 559.009H468.948V558.501C468.981 558.191 468.943 557.876 468.839 557.582C468.734 557.287 468.564 557.02 468.343 556.799C468.121 556.578 467.852 556.409 467.557 556.305C467.261 556.2 466.946 556.163 466.634 556.196C465.593 556.18 464.582 556.54 463.788 557.21L463.279 556.357C464.255 555.58 465.477 555.171 466.726 555.204C467.173 555.151 467.626 555.198 468.052 555.341C468.478 555.484 468.866 555.72 469.19 556.031C469.513 556.342 469.763 556.721 469.921 557.14C470.08 557.56 470.142 558.009 470.105 558.455ZM468.948 561.153V559.769H466.264C464.621 559.769 464.089 560.415 464.089 561.291C464.089 562.167 464.875 562.882 466.241 562.882C466.818 562.92 467.393 562.773 467.88 562.461C468.367 562.15 468.741 561.692 468.948 561.153Z"
                    fill="white"
                  />
                  <path
                    d="M481.143 555.227V562.582C481.234 563.16 481.186 563.751 481.005 564.307C480.823 564.863 480.512 565.369 480.097 565.782C479.682 566.196 479.174 566.506 478.616 566.687C478.058 566.868 477.465 566.916 476.885 566.825C475.446 566.881 474.035 566.423 472.905 565.534L473.484 564.658C474.432 565.422 475.619 565.83 476.839 565.81C479.014 565.81 479.986 564.842 479.986 562.744V561.66C479.62 562.191 479.124 562.62 478.545 562.907C477.966 563.193 477.323 563.328 476.677 563.297C476.108 563.34 475.537 563.265 474.999 563.077C474.461 562.89 473.968 562.593 473.55 562.207C473.132 561.821 472.799 561.353 472.571 560.832C472.343 560.311 472.225 559.75 472.225 559.182C472.225 558.614 472.343 558.052 472.571 557.531C472.799 557.011 473.132 556.543 473.55 556.156C473.968 555.77 474.461 555.474 474.999 555.286C475.537 555.099 476.108 555.024 476.677 555.066C477.334 555.035 477.989 555.173 478.577 555.468C479.164 555.763 479.666 556.204 480.032 556.749V555.135L481.143 555.227ZM480.032 559.262C480.055 558.619 479.885 557.984 479.543 557.439C479.202 556.893 478.704 556.461 478.114 556.199C477.525 555.937 476.87 555.856 476.234 555.968C475.599 556.079 475.011 556.377 474.546 556.823C474.081 557.27 473.761 557.845 473.626 558.474C473.492 559.103 473.549 559.758 473.79 560.354C474.032 560.951 474.446 561.462 474.981 561.822C475.516 562.182 476.147 562.375 476.793 562.375C477.215 562.401 477.638 562.339 478.035 562.193C478.432 562.047 478.794 561.821 479.099 561.528C479.403 561.235 479.644 560.883 479.804 560.493C479.965 560.103 480.042 559.684 480.032 559.262Z"
                    fill="white"
                  />
                  <path
                    d="M490.791 558.456V563.713H489.704V562.398C489.381 562.869 488.939 563.247 488.423 563.494C487.907 563.74 487.336 563.848 486.765 563.805C484.821 563.805 483.641 562.79 483.641 561.315C483.641 559.839 484.497 559.009 486.95 559.009H489.634V558.502C489.634 557.049 488.824 556.196 487.32 556.196C486.287 556.184 485.285 556.544 484.497 557.211L483.965 556.358C484.951 555.58 486.179 555.172 487.436 555.205C487.881 555.156 488.331 555.205 488.754 555.35C489.177 555.494 489.563 555.731 489.884 556.041C490.204 556.352 490.452 556.73 490.609 557.147C490.766 557.565 490.828 558.011 490.791 558.456ZM489.634 561.153V559.77H486.973C485.307 559.77 484.775 560.416 484.775 561.292C484.775 562.168 485.585 562.883 486.95 562.883C487.525 562.923 488.098 562.776 488.582 562.464C489.066 562.152 489.435 561.692 489.634 561.153Z"
                    fill="white"
                  />
                  <path
                    d="M493.661 552.53C493.694 552.331 493.797 552.15 493.952 552.019C494.107 551.888 494.303 551.817 494.506 551.817C494.708 551.817 494.905 551.888 495.059 552.019C495.214 552.15 495.317 552.331 495.35 552.53C495.317 552.729 495.214 552.91 495.059 553.041C494.905 553.172 494.708 553.243 494.506 553.243C494.303 553.243 494.107 553.172 493.952 553.041C493.797 552.91 493.694 552.729 493.661 552.53ZM493.916 555.228H495.073V563.712H493.916V555.228Z"
                    fill="white"
                  />
                  <path
                    d="M506.111 558.778V563.712H504.977V558.893C505.041 558.537 505.021 558.171 504.917 557.824C504.813 557.477 504.628 557.159 504.378 556.896C504.128 556.634 503.82 556.433 503.477 556.312C503.135 556.19 502.769 556.15 502.408 556.196C502.001 556.167 501.592 556.227 501.21 556.372C500.828 556.517 500.483 556.744 500.199 557.036C499.914 557.328 499.697 557.679 499.563 558.064C499.429 558.448 499.382 558.857 499.423 559.262V563.712H498.289V555.227H499.377V556.795C499.722 556.26 500.204 555.826 500.773 555.538C501.343 555.25 501.979 555.119 502.616 555.158C503.097 555.121 503.58 555.19 504.03 555.361C504.481 555.532 504.888 555.8 505.222 556.146C505.556 556.492 505.809 556.908 505.962 557.363C506.116 557.818 506.167 558.301 506.111 558.778Z"
                    fill="white"
                  />
                </g>
                <path
                  opacity="0.4"
                  d="M526.335 621.354H397.377C392.282 621.348 387.397 619.327 383.795 615.734C380.194 612.142 378.172 607.272 378.172 602.194C378.172 597.117 380.194 592.247 383.795 588.654C387.397 585.062 392.282 583.04 397.377 583.034H526.335C531.435 583.034 536.326 585.053 539.932 588.646C543.538 592.239 545.564 597.113 545.564 602.194C545.564 607.276 543.538 612.149 539.932 615.743C536.326 619.336 531.435 621.354 526.335 621.354Z"
                  fill="white"
                />
                <path
                  d="M411.562 601.226C411.551 600.328 411.725 599.438 412.074 598.61C412.423 597.783 412.939 597.035 413.59 596.415C414.241 595.794 415.013 595.314 415.859 595.003C416.704 594.692 417.604 594.558 418.504 594.609C419.423 594.576 420.339 594.74 421.189 595.09C422.039 595.44 422.804 595.968 423.432 596.638L422.229 597.79C421.764 597.291 421.197 596.898 420.566 596.635C419.936 596.373 419.256 596.248 418.573 596.269C417.751 596.276 416.945 596.487 416.225 596.881C415.505 597.276 414.894 597.841 414.447 598.528C414 599.215 413.73 600.002 413.663 600.818C413.595 601.633 413.731 602.453 414.059 603.204C414.387 603.955 414.896 604.613 415.542 605.119C416.187 605.626 416.948 605.966 417.757 606.108C418.567 606.25 419.399 606.19 420.179 605.933C420.959 605.677 421.664 605.232 422.229 604.638L423.432 605.791C422.801 606.462 422.032 606.991 421.178 607.341C420.323 607.691 419.404 607.854 418.481 607.82C417.584 607.871 416.687 607.737 415.844 607.428C415.002 607.118 414.232 606.639 413.583 606.021C412.934 605.402 412.42 604.658 412.072 603.833C411.725 603.008 411.551 602.12 411.562 601.226Z"
                  fill="white"
                />
                <path
                  d="M424.682 601.226C424.617 599.846 424.969 598.478 425.691 597.299C426.414 596.12 427.474 595.183 428.735 594.61C429.997 594.037 431.401 593.853 432.769 594.082C434.136 594.311 435.403 594.943 436.406 595.897C437.41 596.85 438.104 598.081 438.399 599.431C438.694 600.78 438.577 602.187 438.062 603.47C437.548 604.753 436.66 605.853 435.512 606.628C434.365 607.403 433.01 607.819 431.624 607.82C430.727 607.867 429.83 607.731 428.988 607.421C428.146 607.11 427.376 606.632 426.726 606.014C426.076 605.397 425.56 604.654 425.208 603.83C424.857 603.007 424.678 602.121 424.682 601.226ZM436.599 601.226C436.613 600.228 436.328 599.249 435.781 598.412C435.234 597.576 434.45 596.921 433.528 596.531C432.606 596.141 431.589 596.032 430.605 596.22C429.621 596.408 428.716 596.884 428.005 597.586C427.293 598.289 426.807 599.186 426.61 600.165C426.412 601.143 426.511 602.158 426.894 603.08C427.278 604.002 427.928 604.79 428.762 605.342C429.596 605.895 430.576 606.188 431.578 606.183C432.239 606.205 432.898 606.093 433.514 605.852C434.131 605.611 434.691 605.248 435.161 604.783C435.631 604.319 436.001 603.764 436.249 603.153C436.496 602.541 436.615 601.885 436.599 601.226Z"
                  fill="white"
                />
                <path
                  d="M452.427 594.863V607.774H450.9L443.125 598.137V607.774H441.273V594.863H442.801L450.576 604.477V594.863H452.427Z"
                  fill="white"
                />
                <path
                  d="M458.883 596.361H454.44V594.863H465.107V596.453H460.711V607.774H458.883V596.361Z"
                  fill="white"
                />
                <path
                  d="M467.166 594.863H469.018V607.774H467.166V594.863Z"
                  fill="white"
                />
                <path
                  d="M484.058 594.863V607.774H482.531L474.756 598.137V607.774H472.905V594.863H474.363L482.138 604.477V594.863H484.058Z"
                  fill="white"
                />
                <path
                  d="M487.83 602.102V594.863H489.681V602.125C489.681 604.984 491 606.276 493.337 606.276C495.674 606.276 496.993 604.984 496.993 602.125V594.863H498.798V602.195C498.798 603.644 498.22 605.034 497.192 606.058C496.163 607.083 494.769 607.659 493.314 607.659C491.86 607.659 490.465 607.083 489.436 606.058C488.408 605.034 487.83 603.644 487.83 602.195V602.102Z"
                  fill="white"
                />
                <path
                  d="M511.988 606.068V607.682H502.593V594.863H511.849V596.453H504.445V600.419H510.924V601.987H504.445V606.16L511.988 606.068Z"
                  fill="white"
                />
                <path
                  opacity="0.1"
                  d="M686.067 303.241C610.378 378.798 415.981 546.973 195.715 534.523V303.241H686.067Z"
                  fill="white"
                />
                <path
                  opacity="0.1"
                  d="M743.107 442.112V665.185H413.552C426.186 620.132 451.454 558.433 500.464 514.348C588.626 435.033 690.95 435.264 743.107 442.112Z"
                  fill="white"
                />
                <path
                  d="M812.734 267.665L820.416 301.467L828.098 267.665H812.734Z"
                  fill="#407BFF"
                />
                <path
                  opacity="0.7"
                  d="M812.734 267.665L820.416 301.467L828.098 267.665H812.734Z"
                  fill="white"
                />
                <path
                  d="M906.403 171.957H734.452C723.474 171.957 714.575 180.824 714.575 191.762V255.353C714.575 266.291 723.474 275.158 734.452 275.158H906.403C917.38 275.158 926.28 266.291 926.28 255.353V191.762C926.28 180.824 917.38 171.957 906.403 171.957Z"
                  fill="#407BFF"
                />
                <path
                  opacity="0.7"
                  d="M906.403 171.957H734.452C723.474 171.957 714.575 180.824 714.575 191.762V255.353C714.575 266.291 723.474 275.158 734.452 275.158H906.403C917.38 275.158 926.28 266.291 926.28 255.353V191.762C926.28 180.824 917.38 171.957 906.403 171.957Z"
                  fill="white"
                />
                <g opacity="0.5">
                  <path
                    d="M756.852 209.816V214.266H755.024V209.816L749.887 201.423H751.877L756.019 208.34L760.184 201.423H762.012L756.852 209.816Z"
                    fill="#263238"
                  />
                  <path
                    d="M761.595 209.401C761.577 208.39 761.861 207.395 762.412 206.545C762.962 205.695 763.754 205.028 764.687 204.628C765.62 204.228 766.651 204.114 767.649 204.3C768.647 204.486 769.567 204.964 770.291 205.673C771.015 206.382 771.512 207.289 771.717 208.28C771.922 209.271 771.826 210.3 771.442 211.237C771.058 212.173 770.402 212.975 769.56 213.539C768.717 214.103 767.724 214.404 766.709 214.404C766.037 214.436 765.365 214.33 764.736 214.091C764.107 213.852 763.535 213.486 763.055 213.016C762.575 212.546 762.197 211.983 761.946 211.361C761.695 210.738 761.575 210.071 761.595 209.401ZM769.995 209.401C770.033 208.946 769.976 208.489 769.828 208.057C769.68 207.626 769.443 207.229 769.133 206.894C768.823 206.558 768.447 206.29 768.027 206.106C767.608 205.923 767.155 205.828 766.697 205.828C766.24 205.828 765.787 205.923 765.367 206.106C764.948 206.29 764.572 206.558 764.262 206.894C763.952 207.229 763.715 207.626 763.567 208.057C763.419 208.489 763.362 208.946 763.4 209.401C763.362 209.856 763.419 210.313 763.567 210.745C763.715 211.176 763.952 211.573 764.262 211.908C764.572 212.244 764.948 212.512 765.367 212.696C765.787 212.879 766.24 212.974 766.697 212.974C767.155 212.974 767.608 212.879 768.027 212.696C768.447 212.512 768.823 212.244 769.133 211.908C769.443 211.573 769.68 211.176 769.828 210.745C769.976 210.313 770.033 209.856 769.995 209.401Z"
                    fill="#263238"
                  />
                  <path
                    d="M783.393 204.513V214.289H781.727V212.813C781.342 213.327 780.837 213.74 780.257 214.017C779.677 214.295 779.038 214.427 778.395 214.404C777.82 214.463 777.239 214.393 776.696 214.199C776.152 214.004 775.659 213.691 775.252 213.282C774.846 212.873 774.537 212.378 774.348 211.834C774.159 211.29 774.095 210.711 774.16 210.139V204.513H775.942V209.954C775.942 211.868 776.914 212.813 778.603 212.813C779.026 212.841 779.451 212.774 779.845 212.619C780.24 212.463 780.595 212.223 780.885 211.914C781.175 211.605 781.392 211.236 781.522 210.833C781.651 210.431 781.69 210.005 781.634 209.585V204.513H783.393Z"
                    fill="#263238"
                  />
                  <path
                    d="M792.07 204.421V206.15H791.654C791.222 206.122 790.789 206.19 790.387 206.349C789.985 206.508 789.624 206.755 789.329 207.07C789.034 207.386 788.814 207.763 788.684 208.174C788.553 208.585 788.517 209.02 788.576 209.447V214.312H786.748V204.513H788.46V206.173C788.831 205.568 789.368 205.081 790.008 204.771C790.648 204.46 791.363 204.339 792.07 204.421Z"
                    fill="#263238"
                  />
                  <path
                    d="M798.572 207.833C798.512 206.454 798.868 205.088 799.593 203.912C800.319 202.736 801.381 201.803 802.643 201.233C803.905 200.663 805.309 200.483 806.675 200.715C808.04 200.947 809.305 201.581 810.306 202.535C811.308 203.49 811.999 204.721 812.292 206.07C812.586 207.419 812.467 208.825 811.951 210.107C811.436 211.388 810.548 212.487 809.4 213.261C808.253 214.035 806.9 214.449 805.514 214.451C804.615 214.501 803.714 214.367 802.869 214.056C802.024 213.746 801.252 213.265 800.601 212.644C799.95 212.024 799.434 211.277 799.085 210.449C798.736 209.621 798.561 208.731 798.572 207.833ZM810.489 207.833C810.503 206.835 810.218 205.856 809.671 205.02C809.125 204.184 808.34 203.529 807.418 203.138C806.497 202.748 805.479 202.64 804.495 202.828C803.512 203.016 802.606 203.491 801.895 204.194C801.183 204.896 800.698 205.794 800.5 206.772C800.302 207.75 800.401 208.765 800.785 209.687C801.168 210.609 801.818 211.397 802.652 211.95C803.486 212.502 804.466 212.795 805.468 212.791C806.129 212.813 806.789 212.7 807.405 212.459C808.021 212.219 808.581 211.855 809.052 211.391C809.522 210.927 809.892 210.372 810.139 209.76C810.386 209.148 810.505 208.493 810.489 207.833Z"
                    fill="#263238"
                  />
                  <path
                    d="M817.57 202.899H813.127V201.308H823.771V202.899H819.329V214.197H817.501L817.57 202.899Z"
                    fill="#263238"
                  />
                  <path
                    d="M836.313 205.896C836.313 208.686 834.277 210.392 830.899 210.392H827.705V214.289H825.854V201.4H830.899C834.277 201.4 836.313 203.083 836.313 205.896ZM834.462 205.896C834.462 204.052 833.213 202.991 830.852 202.991H827.705V208.778H830.852C833.213 208.778 834.462 207.741 834.462 205.896Z"
                    fill="#263238"
                  />
                  <path
                    d="M843.14 207.833C843.202 206.499 843.65 205.211 844.431 204.125C845.212 203.039 846.292 202.202 847.54 201.714C848.788 201.227 850.151 201.11 851.464 201.377C852.777 201.644 853.985 202.285 854.941 203.222L853.738 204.397C853.273 203.896 852.707 203.498 852.077 203.232C851.446 202.965 850.766 202.836 850.081 202.853C848.762 202.853 847.497 203.375 846.564 204.305C845.631 205.234 845.106 206.495 845.106 207.81C845.106 209.125 845.631 210.385 846.564 211.315C847.497 212.245 848.762 212.767 850.081 212.767C850.766 212.783 851.446 212.654 852.077 212.388C852.707 212.121 853.273 211.724 853.738 211.222L854.941 212.398C853.985 213.335 852.777 213.975 851.464 214.243C850.151 214.51 848.788 214.393 847.54 213.905C846.292 213.418 845.212 212.58 844.431 211.495C843.65 210.409 843.202 209.121 843.14 207.787V207.833Z"
                    fill="#263238"
                  />
                  <path
                    d="M856.005 209.401C855.986 208.39 856.271 207.395 856.821 206.545C857.372 205.695 858.164 205.028 859.097 204.628C860.029 204.228 861.06 204.114 862.058 204.3C863.056 204.486 863.976 204.964 864.701 205.673C865.425 206.382 865.922 207.289 866.126 208.28C866.331 209.271 866.236 210.3 865.852 211.237C865.467 212.173 864.812 212.975 863.969 213.539C863.126 214.103 862.134 214.404 861.119 214.404C860.447 214.436 859.775 214.33 859.146 214.091C858.517 213.852 857.945 213.486 857.464 213.016C856.984 212.546 856.607 211.983 856.356 211.361C856.104 210.738 855.985 210.071 856.005 209.401ZM864.405 209.401C864.443 208.946 864.386 208.489 864.238 208.057C864.089 207.626 863.853 207.229 863.543 206.894C863.233 206.558 862.856 206.29 862.437 206.106C862.018 205.923 861.565 205.828 861.107 205.828C860.649 205.828 860.196 205.923 859.777 206.106C859.358 206.29 858.981 206.558 858.672 206.894C858.362 207.229 858.125 207.626 857.977 208.057C857.828 208.489 857.771 208.946 857.81 209.401C857.771 209.856 857.828 210.313 857.977 210.745C858.125 211.176 858.362 211.573 858.672 211.908C858.981 212.244 859.358 212.512 859.777 212.696C860.196 212.879 860.649 212.974 861.107 212.974C861.565 212.974 862.018 212.879 862.437 212.696C862.856 212.512 863.233 212.244 863.543 211.908C863.853 211.573 864.089 211.176 864.238 210.745C864.386 210.313 864.443 209.856 864.405 209.401Z"
                    fill="#263238"
                  />
                  <path
                    d="M877.918 200.594V214.266H876.229V212.721C875.825 213.259 875.296 213.691 874.687 213.98C874.079 214.27 873.409 214.407 872.735 214.381C871.465 214.295 870.275 213.732 869.406 212.805C868.538 211.879 868.054 210.658 868.054 209.39C868.054 208.121 868.538 206.901 869.406 205.974C870.275 205.047 871.465 204.484 872.735 204.398C873.388 204.378 874.038 204.509 874.632 204.781C875.226 205.053 875.749 205.459 876.16 205.966V200.594H877.918ZM876.16 209.378C876.128 208.733 875.906 208.111 875.523 207.59C875.14 207.069 874.611 206.672 874.003 206.447C873.395 206.223 872.734 206.182 872.103 206.329C871.471 206.475 870.897 206.804 870.451 207.273C870.006 207.742 869.708 208.331 869.596 208.968C869.483 209.604 869.561 210.259 869.819 210.852C870.077 211.444 870.504 211.948 871.047 212.301C871.59 212.654 872.225 212.84 872.874 212.837C873.322 212.847 873.768 212.765 874.182 212.594C874.596 212.423 874.97 212.167 875.28 211.844C875.589 211.52 875.827 211.136 875.979 210.715C876.13 210.294 876.192 209.847 876.16 209.401V209.378Z"
                    fill="#263238"
                  />
                  <path
                    d="M890.113 210H882.176C882.286 210.838 882.715 211.602 883.373 212.134C884.032 212.666 884.871 212.926 885.716 212.859C886.244 212.878 886.77 212.783 887.257 212.58C887.744 212.377 888.182 212.071 888.539 211.684L889.534 212.813C888.809 213.546 887.887 214.053 886.879 214.274C885.871 214.495 884.82 214.421 883.854 214.06C882.887 213.699 882.046 213.067 881.432 212.241C880.818 211.414 880.458 210.428 880.394 209.401C880.368 208.746 880.478 208.093 880.716 207.482C880.954 206.87 881.315 206.314 881.776 205.848C882.238 205.381 882.791 205.014 883.401 204.768C884.011 204.523 884.665 204.404 885.323 204.421C885.987 204.388 886.65 204.498 887.267 204.743C887.884 204.989 888.442 205.364 888.9 205.843C889.359 206.322 889.709 206.894 889.926 207.52C890.144 208.146 890.223 208.811 890.159 209.47C890.154 209.648 890.138 209.825 890.113 210ZM882.176 208.732H888.562C888.495 207.95 888.135 207.222 887.555 206.691C886.974 206.16 886.215 205.865 885.427 205.865C884.639 205.865 883.88 206.16 883.299 206.691C882.719 207.222 882.359 207.95 882.291 208.732H882.176Z"
                    fill="#263238"
                  />
                </g>
                <g opacity="0.5">
                  <path
                    d="M793.69 234.488C793.69 241.75 789.594 245.624 783.601 245.624C781.755 245.689 779.925 245.259 778.302 244.379L779.459 242.073C780.711 242.783 782.139 243.127 783.578 243.065C787.905 243.065 790.659 240.367 790.659 235.041V234.303C789.943 235.238 789.007 235.982 787.933 236.469C786.859 236.956 785.681 237.17 784.503 237.093C783.592 237.193 782.669 237.101 781.795 236.825C780.921 236.548 780.115 236.092 779.428 235.486C778.742 234.88 778.19 234.138 777.809 233.307C777.428 232.475 777.225 231.574 777.214 230.66C777.236 229.717 777.452 228.788 777.847 227.931C778.243 227.073 778.811 226.306 779.515 225.675C780.219 225.045 781.046 224.564 781.944 224.264C782.841 223.963 783.791 223.849 784.735 223.928C790.45 223.882 793.69 227.709 793.69 234.488ZM789.918 230.568C789.918 228.378 788.16 226.464 784.827 226.464C784.246 226.403 783.657 226.464 783.1 226.643C782.543 226.822 782.031 227.116 781.595 227.504C781.159 227.893 780.809 228.369 780.569 228.9C780.328 229.432 780.203 230.008 780.2 230.591C780.2 233.081 782.051 234.695 785.036 234.695C785.63 234.755 786.231 234.695 786.802 234.518C787.373 234.342 787.902 234.052 788.358 233.667C788.814 233.281 789.187 232.808 789.455 232.276C789.723 231.743 789.881 231.162 789.918 230.568Z"
                    fill="#263238"
                  />
                  <path
                    d="M796.606 234.764C796.606 227.847 800.331 223.881 805.329 223.881C810.327 223.881 814.007 227.847 814.007 234.764C814.007 241.681 810.281 245.624 805.329 245.624C800.377 245.624 796.606 241.681 796.606 234.764ZM810.952 234.764C810.952 229.322 808.638 226.602 805.329 226.602C802.02 226.602 799.66 229.322 799.66 234.764C799.66 240.205 801.974 242.926 805.329 242.926C808.685 242.926 810.952 240.205 810.952 234.764Z"
                    fill="#263238"
                  />
                  <path
                    d="M831.847 242.741V245.393H816.505V243.318L825.206 234.971C827.52 232.781 827.867 231.443 827.867 230.106C827.867 227.939 826.317 226.601 823.239 226.601C822.216 226.541 821.192 226.718 820.249 227.118C819.305 227.518 818.468 228.131 817.801 228.907L815.719 227.109C816.697 226.029 817.905 225.179 819.253 224.621C820.602 224.062 822.058 223.81 823.517 223.881C827.96 223.881 830.829 226.186 830.829 229.806C830.794 231.112 830.462 232.393 829.856 233.551C829.251 234.71 828.389 235.716 827.335 236.493L820.809 242.741H831.847Z"
                    fill="#263238"
                  />
                  <path
                    d="M840.964 224.134V245.393H837.932V226.786H833.027V224.134H840.964Z"
                    fill="#263238"
                  />
                  <path
                    d="M845.615 234.764C845.615 227.847 849.341 223.881 854.339 223.881C859.337 223.881 863.016 227.847 863.016 234.764C863.016 241.681 859.314 245.624 854.339 245.624C849.364 245.624 845.615 241.681 845.615 234.764ZM859.985 234.764C859.985 229.322 857.671 226.602 854.339 226.602C851.007 226.602 848.67 229.322 848.67 234.764C848.67 240.205 850.983 242.926 854.339 242.926C857.694 242.926 859.985 240.205 859.985 234.764Z"
                    fill="#263238"
                  />
                </g>
                <path
                  d="M801.395 366.809C815.209 376.334 830.66 383.25 846.98 387.214H847.142C845.921 387.179 844.718 387.517 843.694 388.183C842.686 388.809 841.882 389.714 841.381 390.788C841.211 391.117 841.117 391.479 841.103 391.849C841.473 387.007 839.367 381.565 837.886 376.331C835.225 368.054 832.102 359.569 828.978 351.246L837.91 346.473C845.291 357.748 869.935 393.439 853.112 403.769C850.228 405.445 846.82 405.988 843.556 405.291C825.225 402.585 807.745 395.791 792.417 385.416C791.353 384.678 790.427 384.032 789.177 383.11L801.442 366.763L801.395 366.809Z"
                  fill="#DC897C"
                />
                <path
                  d="M798.804 364.296C806.905 365.269 814.598 368.381 821.087 373.311L811.831 401.855C811.831 401.855 766.848 360.538 798.804 364.296Z"
                  fill="#407BFF"
                />
                <path
                  opacity="0.6"
                  d="M798.711 388.298C802.829 392.997 807.184 397.483 811.761 401.74L815.579 390.004L821.087 373.196C814.604 368.27 806.92 365.158 798.826 364.18C779.158 361.99 788.552 376.654 798.711 388.298Z"
                  fill="white"
                />
                <path
                  opacity="0.2"
                  d="M798.711 388.298C802.829 392.997 807.185 397.483 811.762 401.74L815.58 390.004C810.778 386.276 804.868 384.248 798.781 384.24C798.642 385.588 798.619 386.945 798.711 388.298Z"
                  fill="black"
                />
                <path
                  d="M698.817 322.079C698.817 322.079 677.297 393.37 722.628 402.939C767.958 412.507 772.864 379.697 772.864 379.697C772.864 379.697 735.609 323.531 698.817 322.079Z"
                  fill="#263238"
                />
                <path
                  d="M688.196 345.689C688.113 345.712 688.025 345.712 687.942 345.689C687.876 345.657 687.818 345.613 687.771 345.558C687.723 345.503 687.688 345.439 687.666 345.37C687.644 345.301 687.637 345.228 687.645 345.156C687.652 345.084 687.675 345.014 687.711 344.951C688.387 343.721 689.341 342.664 690.498 341.864C691.654 341.063 692.981 340.542 694.375 340.34C694.519 340.344 694.657 340.4 694.763 340.498C694.869 340.595 694.937 340.727 694.953 340.87C694.96 340.943 694.951 341.016 694.929 341.085C694.907 341.155 694.871 341.22 694.824 341.275C694.777 341.331 694.719 341.377 694.653 341.41C694.588 341.443 694.517 341.464 694.444 341.469C693.238 341.666 692.092 342.133 691.094 342.836C690.095 343.539 689.271 344.46 688.682 345.527C688.623 345.595 688.547 345.645 688.461 345.673C688.376 345.702 688.285 345.707 688.196 345.689Z"
                  fill="#263238"
                />
                <path
                  d="M694.675 354.543C694.385 357.321 693.755 360.055 692.801 362.682C693.518 363.108 694.338 363.333 695.173 363.333C696.008 363.333 696.827 363.108 697.545 362.682L694.675 354.543Z"
                  fill="#DE5753"
                />
                <path
                  d="M690.301 351.43C690.787 352.698 691.875 353.459 692.615 353.136C693.356 352.813 693.772 351.545 693.286 350.277C692.8 349.009 691.713 348.248 690.972 348.571C690.232 348.894 689.838 350.162 690.301 351.43Z"
                  fill="#263238"
                />
                <path
                  d="M691.343 348.502H688.173C688.173 348.502 690.441 350.254 691.343 348.502Z"
                  fill="#263238"
                />
                <path
                  d="M736.373 363.396C736.294 363.603 736.185 363.797 736.049 363.973C735.841 364.365 735.61 364.734 735.378 365.126C734.022 367.555 732.216 369.707 730.056 371.466C723.114 376.977 717.075 378.637 711.822 377.046L711.059 376.792C703.261 373.633 697.175 364.342 692.339 352.052C690.192 347.243 688.841 342.12 688.335 336.881C688.335 336.881 688.335 336.697 688.335 336.651C688.335 336.604 688.335 336.235 688.335 336.051C688.155 333.048 688.729 330.048 690.005 327.323C691.282 324.597 693.222 322.232 695.648 320.442C697.722 318.819 699.973 317.435 702.358 316.315C725.475 305.086 747.966 341.654 736.373 363.396Z"
                  fill="#E4897B"
                />
                <path
                  opacity="0.2"
                  d="M735.378 365.126C734.022 367.556 732.216 369.707 730.056 371.467C723.114 376.977 717.074 378.637 711.822 377.046C716.93 375.813 721.529 373.034 724.988 369.092C726.324 367.714 727.946 366.645 729.741 365.959C731.536 365.274 733.46 364.99 735.378 365.126Z"
                  fill="black"
                />
                <path
                  d="M688.219 336.743C688.219 336.743 694.675 353.667 713.534 353.367C713.534 353.367 728.251 367.201 738.178 362.797C751.714 356.825 754.491 332.293 740.7 315.162C722.79 292.935 683.73 308.96 688.219 336.743Z"
                  fill="#263238"
                />
                <path
                  d="M703.445 352.398C702.242 349.839 702.265 344.605 706.5 343.314C710.734 342.023 718.07 350.992 708.953 358.001C707.657 359.016 705.944 357.701 703.445 352.398Z"
                  fill="#E4897B"
                />
                <path
                  d="M708.513 305.939C708.513 305.939 691.019 309.375 685.373 319.773C678.755 331.855 678.431 341.216 696.017 347.441C696.017 347.441 718.254 326.69 727.834 324.385C733.417 323.004 739.106 322.094 744.842 321.664C738.455 303.242 715.57 298.308 708.513 305.939Z"
                  fill="#263238"
                />
                <path
                  d="M725.474 371.904C725.474 371.904 737.507 380.205 740.631 393.9L778.14 372.089C778.14 372.089 748.776 365.425 743.731 354.105C743.731 354.105 724.109 356.526 725.474 371.904Z"
                  fill="#E4897B"
                />
                <path
                  d="M762.729 461.71C762.729 461.71 714.136 414.144 708.906 399.204C708.906 399.204 729.338 367.501 770.18 366.163C811.021 364.826 838.812 442.25 838.812 442.25L762.729 461.71Z"
                  fill="#407BFF"
                />
                <path
                  opacity="0.6"
                  d="M838.812 442.273L762.752 461.733C762.752 461.733 752.154 451.334 740.306 438.676C736.65 434.733 732.832 430.583 729.153 426.479C719.504 415.573 710.988 404.921 708.929 399.134C708.929 399.134 729.338 367.431 770.18 366.094C811.021 364.756 838.812 442.273 838.812 442.273Z"
                  fill="white"
                />
                <path
                  d="M717.329 345.251C717.329 345.251 724.432 368.769 749.377 376.562C774.322 384.355 768.051 409.741 792.232 406.743C805.097 405.152 816.112 379.075 776.659 356.019C753.658 342.623 762.59 298.584 713.765 302.711C713.719 302.735 723.854 332.916 717.329 345.251Z"
                  fill="#263238"
                />
                <path
                  d="M741.556 820.195L729.986 817.567C731.722 759.925 740.075 690.225 747.457 637.863C748.614 629.655 749.771 621.908 750.835 614.668C751.368 611.233 751.853 607.936 752.339 604.754C754.653 589.052 756.782 576.648 758.055 568.97C759.004 563.367 759.536 560.301 759.536 560.301L801.187 570.422L798.202 580.89L788.368 615.475L787.257 619.418L780.732 642.221L769.671 681.025C770.365 739.612 741.556 820.195 741.556 820.195Z"
                  fill="#E4897B"
                />
                <path
                  d="M840.594 810.558L828.746 809.958C819.722 747.982 815.927 670.466 814.284 616.582C814.053 608.236 813.844 600.442 813.682 593.341C813.682 592.995 813.682 592.649 813.682 592.303C813.382 578.262 813.243 567.102 813.173 560.024C813.173 554.49 813.173 551.47 813.173 551.47L855.912 554.26L854.084 572.013L851.4 598.068L850.868 603.186L849.017 620.94L844.018 668.644C854.94 726.286 840.594 810.558 840.594 810.558Z"
                  fill="#E4897B"
                />
                <path
                  d="M845.037 808.252C845.037 806.776 826.756 807.929 826.756 807.929C826.756 807.929 815.534 819.296 804.635 821.763C793.736 824.23 799.845 830.847 812.017 830.847H842.098C850.799 830.94 845.384 823.585 845.037 808.252Z"
                  fill="#263238"
                />
                <path
                  d="M815.557 812.148C815.293 811.909 815.109 811.595 815.03 811.249C814.951 810.902 814.982 810.54 815.117 810.211C815.189 809.953 815.312 809.712 815.479 809.502C815.646 809.293 815.853 809.118 816.089 808.989C817.385 808.462 818.803 808.303 820.185 808.528C818.704 807.744 817.408 806.799 817.338 805.831C817.338 805.208 817.709 804.701 818.542 804.309C818.905 804.145 819.3 804.06 819.699 804.06C820.098 804.06 820.492 804.145 820.856 804.309C823.332 805.346 824.859 809.589 824.928 809.75C824.977 809.868 824.977 810.001 824.928 810.119V810.234C824.884 810.285 824.829 810.325 824.766 810.35C822.407 811.554 819.854 812.334 817.223 812.655C816.624 812.695 816.031 812.515 815.557 812.148ZM816.575 809.842C816.449 809.906 816.337 809.994 816.246 810.101C816.155 810.207 816.086 810.331 816.043 810.465C815.858 811.18 816.043 811.364 816.043 811.433C816.922 812.148 820.254 811.134 822.985 810.004C820.907 809.35 818.687 809.287 816.575 809.819V809.842ZM818.889 805.231C818.634 805.231 818.218 805.577 818.241 805.831C818.241 806.638 820.902 808.136 823.586 809.082C822.988 807.484 821.894 806.119 820.462 805.185C820.231 805.077 819.978 805.022 819.722 805.024C819.459 805.023 819.198 805.078 818.958 805.185L818.889 805.231Z"
                  fill="#263238"
                />
                <path
                  d="M747.41 808.252C747.41 806.776 729.153 807.929 729.153 807.929C729.153 807.929 717.93 819.296 707.032 821.763C696.133 824.23 702.219 830.847 714.39 830.847H744.472C753.195 830.94 747.804 823.585 747.41 808.252Z"
                  fill="#263238"
                />
                <path
                  d="M717.953 812.148C717.695 811.906 717.515 811.592 717.436 811.247C717.358 810.902 717.385 810.541 717.514 810.211C717.586 809.953 717.709 809.712 717.876 809.502C718.043 809.293 718.25 809.118 718.486 808.989C719.782 808.463 721.2 808.304 722.581 808.528C721.1 807.744 719.805 806.799 719.758 805.831C719.758 805.216 720.152 804.709 720.938 804.309C721.302 804.145 721.696 804.06 722.095 804.06C722.495 804.06 722.889 804.145 723.252 804.309C725.728 805.346 727.256 809.589 727.325 809.75C727.354 809.808 727.368 809.871 727.368 809.935C727.368 809.999 727.354 810.062 727.325 810.119C727.325 810.119 727.325 810.119 727.325 810.234L727.163 810.35C724.794 811.55 722.234 812.33 719.596 812.655C719.006 812.685 718.424 812.506 717.953 812.148ZM718.972 809.842C718.849 809.906 718.74 809.994 718.653 810.101C718.565 810.208 718.5 810.332 718.463 810.465C718.277 811.18 718.463 811.364 718.578 811.433C719.434 812.148 722.767 811.134 725.52 810.004C723.399 809.324 721.128 809.26 718.972 809.819V809.842ZM721.286 805.231C721.031 805.231 720.615 805.577 720.638 805.831C720.638 806.638 723.299 808.136 726.006 809.082C725.399 807.483 724.297 806.118 722.859 805.185C722.628 805.074 722.375 805.019 722.119 805.024C721.855 805.023 721.595 805.078 721.355 805.185L721.286 805.231Z"
                  fill="#263238"
                />
                <path
                  d="M752.872 577.639L762.729 508.469V461.664C788.715 442.204 838.813 442.204 838.813 442.204C838.813 442.204 877.294 495.396 858.597 582.85L810.281 589.767L807.204 573.442L801.141 589.767L752.872 577.639Z"
                  fill="#263238"
                />
                <path
                  opacity="0.2"
                  d="M752.872 577.639L762.729 508.469V461.664C788.715 442.204 838.813 442.204 838.813 442.204C838.813 442.204 877.294 495.396 858.597 582.85L810.281 589.767L807.204 573.442L801.141 589.767L752.872 577.639Z"
                  fill="white"
                />
                <path
                  opacity="0.2"
                  d="M807.204 573.697L816.459 544.899C816.459 544.899 826.224 541.947 838.766 522.626C838.812 522.626 836.637 555.505 807.204 573.697Z"
                  fill="black"
                />
                <path
                  opacity="0.2"
                  d="M786.608 474.967L808.29 472.915C811.751 480.992 812.95 489.852 811.761 498.554L797.877 506.878L787.812 499.753C787.812 499.753 787.488 481.469 786.608 474.967Z"
                  fill="black"
                />
                <path
                  opacity="0.2"
                  d="M838.812 469.688C841.722 476.813 843.87 484.225 845.222 491.799L852.904 495.05L857.046 489.908C857.046 489.908 854.247 474.852 849.503 467.474L838.812 469.688Z"
                  fill="black"
                />
                <path
                  d="M751.969 573.697L803.246 588.683L800.03 595.554L751.321 580.614L751.969 573.697Z"
                  fill="#263238"
                />
                <path
                  d="M808.291 587.692L809.147 594.01L859.429 587.692L860.193 579.738L808.291 587.692Z"
                  fill="#263238"
                />
                <path
                  d="M818.843 451.911C827.068 450.527 835.38 449.718 843.718 449.49L840.617 444.394C832.508 444.181 824.394 444.659 816.367 445.824L818.843 451.911Z"
                  fill="#263238"
                />
                <path
                  d="M772.725 458.182C766.084 461.041 762.266 463.186 762.266 463.186V469.48C765.999 467.605 769.74 465.891 773.489 464.339L772.725 458.182Z"
                  fill="#263238"
                />
                <path
                  d="M775.918 463.44C788.78 458.415 802.124 454.715 815.742 452.395L813.428 446.262C800.227 448.456 787.323 452.154 774.969 457.283L775.918 463.44Z"
                  fill="#263238"
                />
                <path
                  d="M636.895 481.446L623.752 476.189L621.438 488.548C621.438 488.548 633.286 495.695 638.238 487.187L636.895 481.446Z"
                  fill="#E4897B"
                />
                <path
                  d="M617.227 476.95L613.016 486.496L621.531 488.548L623.753 476.189L617.227 476.95Z"
                  fill="#E4897B"
                />
                <path
                  d="M838.604 348.618L837.053 334.599L825.067 338.473C825.067 338.473 824.327 352.307 834.207 352.583L838.604 348.618Z"
                  fill="#E4897B"
                />
                <path
                  d="M833.281 329.227L822.845 330.057L825.067 338.472L837.053 334.599L833.281 329.227Z"
                  fill="#E4897B"
                />
                <path
                  opacity="0.2"
                  d="M740.307 438.746C736.65 434.803 732.832 430.653 729.153 426.549C727.742 417.972 726.839 410.409 726.839 410.409C731.247 413.667 734.781 417.957 737.13 422.899C739.479 427.841 740.57 433.284 740.307 438.746Z"
                  fill="black"
                />
                <path
                  d="M728.181 406.444C722.881 427.735 714.88 448.268 704.371 467.544C693.958 487.257 654.366 489.632 634.466 490.139L633.54 480.087C645.526 477.274 682.781 469.873 687.872 459.105C696.183 440.45 703.022 421.178 708.327 401.463L728.181 406.398V406.444Z"
                  fill="#DC897C"
                />
                <path
                  d="M716.843 389.059C705.527 396.437 699.465 415.62 697.544 425.004L721.054 442.25L732.925 430.722C737.738 404.253 721.146 386.269 716.843 389.059Z"
                  fill="#407BFF"
                />
                <path
                  opacity="0.6"
                  d="M716.843 389.059C705.527 396.437 699.465 415.62 697.544 425.004L721.054 442.25L732.925 430.722C737.738 404.253 721.146 386.269 716.843 389.059Z"
                  fill="white"
                />
                <path
                  d="M831.616 353.114L814.816 354.912C814.58 354.919 814.344 354.876 814.125 354.788C813.905 354.699 813.706 354.566 813.541 354.397C813.376 354.228 813.248 354.027 813.164 353.806C813.081 353.585 813.045 353.349 813.058 353.114L812.04 317.675C812.007 317.175 812.158 316.679 812.465 316.28C812.771 315.882 813.212 315.607 813.706 315.508L830.482 313.733C830.72 313.722 830.957 313.762 831.178 313.85C831.399 313.937 831.599 314.07 831.765 314.24C831.931 314.41 832.059 314.612 832.141 314.835C832.223 315.057 832.257 315.295 832.241 315.531L833.259 350.969C833.291 351.464 833.143 351.954 832.841 352.348C832.539 352.742 832.103 353.014 831.616 353.114Z"
                  fill="#263238"
                />
                <path
                  opacity="0.6"
                  d="M831.616 353.114L814.816 354.912C814.58 354.919 814.344 354.876 814.125 354.788C813.905 354.699 813.706 354.566 813.541 354.397C813.376 354.228 813.248 354.027 813.164 353.806C813.081 353.585 813.045 353.349 813.058 353.114L812.04 317.675C812.007 317.175 812.158 316.679 812.465 316.28C812.771 315.882 813.212 315.607 813.706 315.508L830.482 313.733C830.72 313.722 830.957 313.762 831.178 313.85C831.399 313.937 831.599 314.07 831.765 314.24C831.931 314.41 832.059 314.612 832.141 314.835C832.223 315.057 832.257 315.295 832.241 315.531L833.259 350.969C833.291 351.464 833.143 351.954 832.841 352.348C832.539 352.742 832.103 353.014 831.616 353.114Z"
                  fill="white"
                />
                <path
                  d="M830.134 353.275L813.358 355.05C813.121 355.063 812.883 355.027 812.661 354.942C812.439 354.858 812.237 354.727 812.069 354.56C811.9 354.392 811.77 354.191 811.685 353.969C811.6 353.748 811.563 353.511 811.577 353.275L810.581 317.813C810.539 317.316 810.683 316.821 810.987 316.425C811.291 316.029 811.732 315.759 812.224 315.669L829.001 313.894C829.236 313.884 829.471 313.923 829.691 314.009C829.91 314.095 830.109 314.226 830.275 314.393C830.441 314.56 830.569 314.76 830.653 314.98C830.736 315.2 830.772 315.435 830.759 315.669L831.777 351.13C831.82 351.627 831.676 352.122 831.372 352.519C831.068 352.915 830.627 353.185 830.134 353.275Z"
                  fill="#263238"
                />
                <path
                  opacity="0.7"
                  d="M830.134 353.275L813.358 355.05C813.121 355.063 812.883 355.027 812.661 354.942C812.439 354.858 812.237 354.727 812.069 354.56C811.9 354.392 811.77 354.191 811.685 353.969C811.6 353.748 811.563 353.511 811.577 353.275L810.581 317.813C810.539 317.316 810.683 316.821 810.987 316.425C811.291 316.029 811.732 315.759 812.224 315.669L829.001 313.894C829.236 313.884 829.471 313.923 829.691 314.009C829.91 314.095 830.109 314.226 830.275 314.393C830.441 314.56 830.569 314.76 830.653 314.98C830.736 315.2 830.772 315.435 830.759 315.669L831.777 351.13C831.82 351.627 831.676 352.122 831.372 352.519C831.068 352.915 830.627 353.185 830.134 353.275Z"
                  fill="white"
                />
                <path
                  d="M829.024 314.586L824.627 315.047L824.072 316.407L817.269 317.122L816.644 315.9L812.248 316.361C811.929 316.427 811.646 316.606 811.45 316.865C811.254 317.124 811.159 317.444 811.183 317.768L812.178 353.206C812.168 353.36 812.191 353.514 812.245 353.659C812.3 353.803 812.385 353.934 812.494 354.043C812.604 354.153 812.736 354.237 812.881 354.292C813.026 354.346 813.181 354.369 813.335 354.358L830.111 352.583C830.428 352.522 830.711 352.347 830.907 352.093C831.103 351.838 831.199 351.52 831.176 351.2L830.181 315.739C830.187 315.585 830.162 315.433 830.106 315.29C830.05 315.147 829.965 315.017 829.856 314.909C829.748 314.8 829.618 314.716 829.474 314.66C829.331 314.605 829.177 314.579 829.024 314.586Z"
                  fill="#407BFF"
                />
                <path
                  opacity="0.8"
                  d="M826.779 327.405L814.446 328.719C814.295 328.726 814.145 328.701 814.004 328.647C813.864 328.592 813.736 328.509 813.63 328.403C813.523 328.297 813.44 328.169 813.385 328.029C813.33 327.889 813.306 327.74 813.312 327.589V324.177C813.283 323.859 813.374 323.542 813.566 323.286C813.758 323.031 814.039 322.855 814.353 322.794L826.71 321.479C826.861 321.476 827.011 321.504 827.15 321.561C827.29 321.618 827.415 321.704 827.52 321.812C827.624 321.921 827.705 322.05 827.757 322.191C827.809 322.332 827.83 322.482 827.821 322.632L827.936 326.045C827.956 326.376 827.847 326.702 827.631 326.956C827.416 327.209 827.111 327.37 826.779 327.405Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default NumeroClient